// extracted by mini-css-extract-plugin
export var bg = "smallSlider-module--bg--NNA0k";
export var blue = "smallSlider-module--blue--WlWh4";
export var clicked = "smallSlider-module--clicked--0XcQc";
export var pink = "smallSlider-module--pink--xDaXa";
export var smallSlider = "smallSlider-module--smallSlider--LxRWl";
export var smallSliderContentWrapLeft = "smallSlider-module--smallSliderContentWrapLeft--qAOyq";
export var smallSliderContentWrapRight = "smallSlider-module--smallSliderContentWrapRight--y-Kfk";
export var smallSliderLeft = "smallSlider-module--smallSliderLeft--F+oQs";
export var smallSliderRight = "smallSlider-module--smallSliderRight--9VAIc";
export var smallSliderWrap = "smallSlider-module--smallSliderWrap--TaChk";